import { useState, useEffect } from "react";
import "../../Assets/css/GoFundMePopup/GoFundMePopup.css";
const GoFundMePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if the popup has been shown before in this session
    const hasPopupBeenShown = sessionStorage.getItem('hasPopupBeenShown');

    if (!hasPopupBeenShown) {
      setIsOpen(true);
      // Set the flag in sessionStorage
      sessionStorage.setItem('hasPopupBeenShown', 'true');
    }
  }, []);

  // If popup is closed, return null to hide it
  if (!isOpen) return null;

  return (
    <div className="gfm-overlay">
      <div className="gfm-popup">
        <button onClick={() => setIsOpen(false)} className="gfm-closeButton">
          ✖
        </button>
        {/* GoFundMe Widget */}
        <iframe
        title="myGoFund"
          src="https://www.gofundme.com/f/f55hy-fund-raising-for-church-building-project/widget/large?sharesheet=manage%20hero&attribution_id=sl:fe93d46d-2557-4465-85ba-cc13971a64a4"
          className="gfm-widget"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default GoFundMePopup;