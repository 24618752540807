import React from "react";
import "../Assets/css/About/About.css";
import "../Assets/css/styles.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div className="text-center mt-5">
        <h1 className="green-underline">About Us</h1>
      </div>
      <article className="about-intro">
        <div className="mt-1">
          <div className="about_top row">
            <div className="col-lg-7 mx-3 col-sm-11 col-xs-12 about-us-intro">
              TNSDA is the first Nigerian SDA Church in Canada. We are a growing congregation committed to proclaiming the Gospel of Christ in an
              engaging, spirit-filled weekly services rooted in delightful Nigerian/African praise and worship culture.
              <p>
                Within the 9 years of our journey and by God’s grace, we have progressively attained membership of 86 listed men and women and we have been able
                to consistently reach out to our immediate communities with our various departments targeting men, women, youth, seniors, widows, bereaved, new
                immigrants, refugees, needy, etc.
              </p>
            </div>
            <div className="col-lg-4 col-sm-11 col-xs-12">
              <img className="w-100 shadow-lg p-1 bg-white rounded about-top-image" src="/images/tnsda-about.jpg" alt="" />
            </div>
          </div>
        </div>
      </article>
      <article className="our-values mb-5">
        <div className="my-5 text-center">
          <h3 className="mb-5">Our Values</h3>
          <div className="row">
            <div className="col-sm">
              <i className="fa-solid fa-3x fa-book-bible"></i>
              <h6 className="mt-3">Doctrine</h6>
              <p className="px-3">We believe in the Bible and the Bible alone.</p>
            </div>
            <div className="col-sm">
              <i className="fa-solid fa-3x fa-hand-holding-hand"></i>
              <h6 className="mt-3">Service</h6>
              <p className="px-3">We render our service to God and humanity.</p>
            </div>
            <div className="col-sm">
              <i className="fa-solid fa-3x fa-person-praying"></i>
              <h6 className="mt-3">Devotion</h6>
              <p className="px-3">Devoted to living and proclaiming the gospel.</p>
            </div>
          </div>
        </div>
      </article>

      <article className="more-values mb-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col-sm-12 col-lg-6">
              <img src="/images/church-5.jpg" className="value-image" alt="" />
            </div>
            <div className="col-sm-12 col-lg-6 value-description text-center">
              <h3 className="mt-sm-4 mt-xs-4">Fellowship</h3>
              <p className="text-center mt-lg-4">
                We are a group of passionate, selfless, committed and warm individuals who are open to receiving new members every Sabbath; welcoming them into
                our Father’s house. Our prayer meetings are testimony packed, our youth programs are exciting and impactful, you will especially enjoy our
                unique Sabbath School lesson study sessions as well as other numerous programs run by various departments within the Church.
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-sm-12 col-lg-6 value-description text-center">
              <h3 className="mt-lg-5 mt-xs-4">Hospitality</h3>
              <p className="text-center mt-lg-3">
                Our hospitality team is committed to feeding everyone who so wishes to partake in our bi-weekly potluck; making sure everyone has enough to eat
                right before Bible study at 4pm, every other Sabbath.
              </p>
            </div>
            <div className="col-sm-12 col-lg-6">
              <img src="/images/church-2.jpg" className="value-image" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <img src="/images/church-3.jpg" className="value-image" alt="" />
            </div>
            <div className="col-sm value-description text-center">
              <h3 className="mt-sm-4">Stewardship</h3>
              <p className="text-center">
                One pertinent goal of ours is to own a Church facility without restrictive rental conditions which will enable us do much more outreach programs
                than we currently do. Our Stewardship team has embarked on a purposeful mission to help accomplish this goal. The Stewardship{" "}
                <Link className="" to="/vision_2025">
                  <u>”Vision 2025”</u>
                </Link>{" "}
                is an initiative to raise a total of $1,000,000 by the year 2025. This is certainly daunting for us, but most definitely NOT for our very big
                God. We beseech you to support us in whatever capacity you can, please click on the link.{" "}
                <Link className="" to="/vision_2025">
                  <i class="fa-solid fa-up-right-from-square"></i>
                </Link>
              </p>
              <p className="text-center">Thanks and many blessings!</p>
            </div>
          </div>
        </div>
      </article>

      {/* <article className='container text-center mt-5'>
        <div>
        <h3 className='mb-5'>Contacts</h3>
          <OfficerTable />
        </div>
      </article> */}
    </div>
  );
};

export default About;
