import { useEffect } from "react";

// Adding google analytics
import ReactGA from "react-ga4";
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const GoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
};

export default GoogleAnalytics;
